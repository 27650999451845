export default function main() {
  const iconMenu = document.querySelector('.menu__icon');
  const menuBody = document.querySelector('.header__menu');
  if (iconMenu) {
    iconMenu.addEventListener('click', function (e) {
      document.body.classList.toggle('_lock');
      iconMenu.classList.toggle('_active');
      menuBody.classList.toggle('_active');
    })
  }

  const acc = document.querySelectorAll(".faq__item");
  acc.forEach((accItem) => {
    accItem.addEventListener("click", function () {
      this.classList.toggle("active");
      const head = this.querySelector('.faq__head');
      const panel = this.querySelector('.faq__desc');
      panel.classList.toggle("active");
      head.classList.toggle("active");
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 25 + "px";
      }
    })
  });



}


